import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, Card, CardBody, Container, FormGroup, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import _ from 'lodash';
import styled from 'styled-components';
import { createHubRole, getRoleGroups, updateHubRole } from '../../services';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import toastr from 'toastr';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
});

const ErrorMsg = styled.p`
  color: #e23449;
  font-size: 14px;
`;

function AddEditRole(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  const [groups, setGroups] = useState<any>();
  const [editrole, setEditRole] = useState<any>();
  const [error, setError] = useState('');
  const state = props?.location?.state?.roles;
  const roleId = params.id as string;
  const breadCrumbItems = [
    {
      link: '/roles',
      text: 'Role Management',
    },
    {
      link: '/roles',
      text: roleId ? 'Edit Role' : 'Add Role',
    },
  ];

  const initialValue = {
    name: editrole?.name ?? '',
    description: editrole?.description ?? '',
    groups: editrole?.groups ?? [],
  };

  const getRoleGroup = async () => {
    const res = await getRoleGroups();
    const initRole = res.data.groups.map((data: any) => {
      return {
        id: data._id,
        name: data.name,
        isChecked: false,
      };
    });
    setGroups(initRole);
    if (editrole?._id) {
      editrole?.groups?.map((p: any) => {
        const index = _.findIndex(initRole, { id: p._id });
        initRole[index].isChecked = true;
        setGroups([...initRole]);
      });
    }
  };

  const handleChangeCheck = (handleChange: any) => (event: any) => {
    const index = _.findIndex(groups, { id: event.target.value });
    const roleGroup = [...groups];
    roleGroup[index].isChecked = event.target.checked;
    setGroups(roleGroup);
    handleChange(event);
  };
  const initData = () => {
    const index = _.findIndex(state ?? [], { _id: roleId });
    const roleData = state ? state[index] : {};
    console.log(roleData);
    setEditRole(roleData);
  };

  useEffect(() => {
    getRoleGroup();
  }, [editrole]);

  useEffect(() => {
    initData();
  }, []);

  return (
    <div className="page-content">
      <Container fluid className="container-page">
        <Breadcrumb items={breadCrumbItems} />
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={initialValue}
                  validationSchema={validationSchema}
                  onSubmit={async (values, { setFieldError }) => {
                    console.log(groups);
                    const groupIds = groups.filter((p: any) => p.isChecked).map((p: any) => p.id);
                    if (!groupIds.length) {
                      // toastr.error('At least one group must be selected.', 'Error');
                      setFieldError('groups', 'At least one group must be selected.');
                      return;
                    }
                    let res;
                    try {
                      if (editrole?._id) {
                        const roleId = {
                          id: editrole?._id,
                        };
                        res = await updateHubRole({ ...values, groups: groupIds }, roleId);
                        toastr.success('Role Successfully Updated.', 'Success');
                      } else {
                        res = await createHubRole({ ...values, groups: groupIds });
                        toastr.success('Role Successfully Created.', 'Success');
                      }
                      if (res) {
                        navigate('/roles');
                      }
                    } catch (err: any) {
                      setError(err?.response?.data?.message);
                    }
                  }}
                >
                  {({ errors, touched, values, handleChange }) => {
                    return (
                      <>
                        <Form>
                          <div className="login-form form-group">
                            <FormGroup>
                              <div>
                                <Label for="name">Role</Label>
                                <Field
                                  name="name"
                                  type="text"
                                  id="name"
                                  value={values.name}
                                  placeholder="Enter Your name"
                                  className={`form-control ${
                                    touched.name && errors.name ? 'is-invalid' : ''
                                  }`}
                                  style={{ marginTop: '0px' }}
                                />
                              </div>
                              {errors.name && touched.name ? (
                                <ErrorMsg>{errors.name}</ErrorMsg>
                              ) : null}
                            </FormGroup>
                            <FormGroup>
                              <div>
                                <Label for="description">Role description</Label>
                                <Field
                                  name="description"
                                  id="description"
                                  type="text"
                                  value={values.description}
                                  placeholder="Enter Your role description"
                                  className={`form-control ${
                                    touched.description && errors.description ? 'is-invalid' : ''
                                  }`}
                                  style={{ marginTop: '0px' }}
                                />
                              </div>
                              {errors.description && touched.description ? (
                                <ErrorMsg>{errors.description}</ErrorMsg>
                              ) : null}
                            </FormGroup>
                            <div role="group" aria-labelledby="checkbox-group">
                              <h5>Select Groups</h5>
                              {groups?.map((role: any, index: number) => {
                                return (
                                  <div key={index}>
                                    <div className="custom-control custom-checkbox mb-1">
                                      <input
                                        type="checkbox"
                                        id={role.id}
                                        className="custom-control-input"
                                        name={'groups'}
                                        value={role.id}
                                        checked={role.isChecked}
                                        onChange={handleChangeCheck(handleChange)}
                                      />
                                      <label className="custom-control-label" htmlFor={role.id}>
                                        {role.name}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                              {errors.groups && touched.groups ? (
                                <ErrorMsg>{errors.groups}</ErrorMsg>
                              ) : null}
                            </div>
                          </div>
                          {error ? <ErrorMsg>{error}</ErrorMsg> : null}
                          <Button color="secondary" onClick={() => navigate('/roles')}>
                            Cancel
                          </Button>{' '}
                          <Button color="primary" type="submit">
                            {editrole?._id ? 'Update' : 'Save'}
                          </Button>
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AddEditRole;
