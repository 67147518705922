import React, { useState, useEffect } from 'react';

import { Cookies } from 'react-cookie';
import { MenuItem, BreadCrumb } from '../constants/types';

const cookies = new Cookies();

export interface IDataContext {
  footer: string;
  dashTitle: string;
  setDashTitle: (val: string) => void;
  title_text: string;
  setTitleText: (val: string) => void;
  footer_text: string;
  setFooterText: (val: string) => void;
  footer_text_right: string;
  setFooterRightText: (val: string) => void;
  menuItems: Array<MenuItem>;
  setMenuItems: (val: Array<MenuItem>) => void;
  breadCrumbs: BreadCrumb[];
  setBreadCrumbs: (val: BreadCrumb[]) => void;
  logo: string;
  logoSm: string;

  lang: string;
  leftSideBarType: string;
  setLeftSideBarType: (val: string) => void;
  changeLanguage: (val: string) => void;
  userProjects: Array<any>;
  setUserProjects: (val: Array<any>) => void;
  allSites: Array<any>;
  setAllSites: (val: Array<any>) => void;
}

const defaultState = {
  parentTitle: '',
  childTitle: '',
  listLink: '',
  footer: '',

  dashTitle: '',
  setDashTitle: () => {},
  title_text: 'LAINA EDC PORTAL',
  setTitleText: () => {},
  footer_text: new Date().getFullYear() + ' © Laina',
  setFooterText: () => {},
  footer_text_right: 'Current Server time: ' + new Date().getFullYear(),
  setFooterRightText: () => {},
  menuItems: [] as Array<MenuItem>,
  setMenuItems: () => {},
  logo: 'logo-laina-hub.png',
  logoSm: 'laina-logo-sm.png',
  breadCrumbs: [],
  setBreadCrumbs: () => {},

  lang: 'en',
  leftSideBarType: 'default',
  setLeftSideBarType: () => {},
  changeLanguage: () => {},
  userProjects: [],
  setUserProjects: () => {},
  allSites: [],
  setAllSites: () => {},
};

export const DataContext: React.Context<IDataContext> =
  React.createContext<IDataContext>(defaultState);

function changeSidebarType(sidebarType: string) {
  switch (sidebarType) {
    case 'compact':
      changeBodyAttribute('data-sidebar-size', 'small');
      manageBodyClass('sidebar-enable', 'remove');
      manageBodyClass('vertical-collpsed', 'remove');
      break;
    case 'icon':
      changeBodyAttribute('data-keep-enlarged', 'true');
      manageBodyClass('vertical-collpsed', 'add');
      break;
    case 'condensed':
      manageBodyClass('sidebar-enable', 'add');
      manageBodyClass('vertical-collpsed', 'add');
      break;
    case 'menu-opened':
      manageBodyClass('sidebar-enable', 'add');
      manageBodyClass('menu-opened', 'add');
      manageBodyClass('vertical-collpsed', 'add');
      break;
    case 'menu-closed':
      manageBodyClass('sidebar-enable', 'add');
      manageBodyClass('menu-opened', 'remove');
      manageBodyClass('vertical-collpsed', 'add');
      break;
    default:
      changeBodyAttribute('data-sidebar-size', '');
      manageBodyClass('sidebar-enable', 'remove');
      manageBodyClass('vertical-collpsed', 'remove');
      manageBodyClass('menu-opened', 'remove');
      break;
  }
}

function changeBodyAttribute(attribute: string, value: string) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass: string, action = 'toggle') {
  switch (action) {
    case 'add':
      if (document.body) document.body.classList.add(cssClass);
      break;
    case 'remove':
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}

export const DataProvider = ({ children }: { children: any }): JSX.Element => {
  const [dashTitle, setDashTitle] = useState<string>('');
  const [title_text, setTitleText] = useState<string>('');
  const [footer_text, setFooterText] = useState<string>(new Date().getFullYear() + ' © Laina');
  const [footer_text_right, setFooterRightText] = useState<string>('');
  const [menuItems, setMenuItems] = useState<Array<MenuItem>>([]);
  const [breadCrumbs, setBreadCrumbs] = useState<Array<BreadCrumb>>([]);
  const [allSites, setAllSites] = useState<Array<any>>([]);
  const logo = 'logo-laina-hub.png';
  const logoSm = 'laina-logo-sm.png';
  const [leftSideBarType, setLeftSideBarType] = useState<string>('default');
  const [userProjects, setUserProjects] = useState<Array<any>>([]);

  function changeLanguage(lang: string) {}

  useEffect(() => {
    changeSidebarType(leftSideBarType);
  }, [leftSideBarType]);

  const value = {
    mobile_config: {},
    dataDictionaryMaster: undefined,
    participantOverviewFields: [],
    records: [],
    parentTitle: '',
    childTitle: '',
    listLink: '',
    footer: '',

    dashTitle,
    setDashTitle,
    title_text,
    setTitleText,
    footer_text,
    setFooterText,
    footer_text_right,
    setFooterRightText,
    menuItems,
    setMenuItems,
    breadCrumbs,
    setBreadCrumbs,
    logo,
    logoSm,

    lang: 'en',
    leftSideBarType,
    setLeftSideBarType,
    changeLanguage,
    userProjects,
    setUserProjects,

    allSites,
    setAllSites,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useData = (): IDataContext => React.useContext(DataContext);
