import React, { useEffect } from 'react';

import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';

// layouts Format
import AuthLayout from './components/AuthLayout';
import NonAuthLayout from './components/NonAuthLayout';

import { useAuth } from './context/AuthContext';

// Import scss
import './assets/scss/theme.scss';

// Fontawesome
import '@fortawesome/fontawesome-pro/js/all';

import Login from './pages/Authentication/Login';
import CoordinatorLogin from './pages/Authentication/CoordinatorLogin';
import ForgetPwd from './pages/Authentication/ForgetPassword';
import StudiesPage from './pages/StudiesPage';

import ChangePwd from './pages/ChangePassword';
import SetPwd from './pages/Authentication/SetPassword';
import ResetPwd from './pages/Authentication/ResetPassword';
import IdleWatch from './components/IdleWatch';

import Roles from './pages/Roles/Roles';
import RoleEdit from './pages/Roles/RoleEdit';
import Users from './pages/Users/Users';
import UserCreate from './pages/Users/UserCreate';
import UserDetail from './pages/Users/UserDetail';
import Studies from './pages/Studies/Studies';
import StudyDetail from './pages/Studies/StudyDetail';
import Sites from './pages/Studies/Studies';
import SiteAddEdit from './pages/Studies/SiteAddEdit';
import Profile from './pages/Profile';

const guestRoutes = [
  { path: '/login', component: Login },
  { path: '/studycoordinator', component: CoordinatorLogin },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/password/:id', component: SetPwd },
  { path: '/reset-password-link/:token', component: ResetPwd },
];

const internalRoutes = [
  { path: '/dashboard', component: StudiesPage },
  { path: '/changepassword', component: ChangePwd },
  { path: '/profile', component: Profile },
  { path: '/roles', component: Roles, permission: 'admin' },
  { path: `/roles/create`, component: RoleEdit, permission: 'admin' },
  { path: `/roles/:id`, component: RoleEdit, permission: 'admin' },
  { path: '/users', component: Users, permission: 'admin' },
  { path: '/users/create', component: UserCreate, permission: 'admin' },
  { path: '/users/:id', component: UserDetail, permission: 'admin' },
  { path: '/studies', component: Studies, permission: 'admin' },
  { path: '/studies/:id', component: StudyDetail, permission: 'admin' },
  { path: '/sites', component: Sites, permission: 'admin' },
  { path: '/sites/:studyId/create', component: SiteAddEdit, permission: 'admin' },
  { path: '/sites/:studyId/:id', component: SiteAddEdit, permission: 'admin' },
];

const App = () => {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <React.Fragment>
      <IdleWatch />

      <Routes>
        <Route path="/" element={<AuthLayout />}>
          {internalRoutes.map(({ component, path, permission }, idx) => {
            return (
              <Route
                path={path}
                key={idx}
                element={
                  isAuthenticated ? (
                    ['superadmin', 'admin'].includes(
                      user.hub_access_type?.toLowerCase() || 'user',
                    ) || permission != 'admin' ? (
                      React.createElement(component, { navigate, location })
                    ) : (
                      <Navigate to={{ pathname: '/dashboard' }} />
                    )
                  ) : (
                    <Navigate to={{ pathname: '/studycoordinator' }} />
                  )
                }
              />
            );
          })}
          <Route
            index
            element={
              isAuthenticated ? (
                <Navigate to={{ pathname: '/dashboard' }} />
              ) : (
                <Navigate to={{ pathname: '/login' }} />
              )
            }
          />
        </Route>
        <Route path="/" element={<NonAuthLayout />}>
          {guestRoutes.map(({ component, path }, idx) => {
            return (
              <Route
                path={path}
                key={idx}
                element={
                  !isAuthenticated ? (
                    React.createElement(component, {})
                  ) : (
                    <Navigate to={{ pathname: '/dashboard' }} />
                  )
                }
              />
            );
          })}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

App.displayName = 'App';

export default App;
