import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { MenuItem } from '../../constants/types';
import SidebarMenuLink from './SidebarMenuLink';
import SidebarMenu from './SidebarMenu';

import { useData } from '../../context/DataContext';
import { useAuth } from '../../context/AuthContext';

type IntlTitleProps = 'title_en';

const SidebarContent = () => {
  const { lang, leftSideBarType, logo, logoSm, setLeftSideBarType } = useData();
  const { user } = useAuth();
  const logoImage = require('../../assets/images/' + logo);
  const logoSmImage = require('../../assets/images/' + logoSm);

  const [openedMenu, setOpenedMenu] = useState<MenuItem | undefined>(undefined);

  const menuItems: MenuItem[] = [
    {
      item_type: 'Link',
      title_en: 'Your Studies',
      tooltip_en: 'Display Your Studies',
      icon: 'fas fa-home',
      route: '/dashboard',
      theme: 'standard',
      children: [],
    },
    {
      item_type: 'Menu',
      title_en: 'Admin Setting',
      tooltip_en: 'Admin Setting',
      icon: 'fas fa-gears',
      theme: 'standard',
      permission: ['superadmin', 'admin'],
      children: [
        {
          title_en: '',
          item_type: 'Link',
          subtitle_en: 'Manage Roles',
          tooltip_en: 'Manage Roles',
          icon: 'fas fa-tools',
          route: '/roles',
          theme: 'standard',
          children: [],
        },
        {
          title_en: '',
          item_type: 'Link',
          subtitle_en: 'Manage Users',
          tooltip_en: 'Manage users',
          icon: 'fas fa-users',
          route: '/users',
          theme: 'standard',
          children: [],
        },
        {
          title_en: '',
          item_type: 'Link',
          subtitle_en: 'Manage Studies',
          tooltip_en: 'Manage Studies',
          icon: '	fas fa-tasks',
          route: '/studies',
          theme: 'standard',
          children: [],
        },
      ],
    },
  ];

  function getTitlePropName(lang: string): IntlTitleProps {
    return lang === 'en' ? 'title_en' : 'title_en';
  }
  useEffect(() => {
    if (['condensed', 'menu-opened', 'menu-closed'].includes(leftSideBarType)) {
      if (!!openedMenu) {
        setLeftSideBarType('menu-opened');
      } else {
        setLeftSideBarType('menu-closed');
      }
    }
  }, [openedMenu]);

  useEffect(() => {
    if (openedMenu && leftSideBarType === 'menu-closed') {
      setOpenedMenu(undefined);
    }
  }, [leftSideBarType]);

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <div className="navbar-brand-box">
          <Link to="/dashboard" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logoSmImage} alt="" height="44" />
            </span>
            <span className="logo-lg">
              <img src={logoImage} alt="" height="50" />
            </span>
          </Link>
        </div>
        <ul className="metismenu list-unstyled mm-active" id="side-menu">
          {menuItems.map((item, i) => {
            if (item.permission == null || item.permission?.includes(user.hub_access_type))
              return item.item_type === 'Link' ? (
                <SidebarMenuLink
                  key={i}
                  link={item}
                  label={item[getTitlePropName(lang)] || ''}
                  index={`${i}-0`}
                />
              ) : (
                <SidebarMenu
                  key={i}
                  menu={item}
                  index={i}
                  collapse={item !== openedMenu}
                  onCollapse={(menu) => setOpenedMenu(menu)}
                />
              );
          })}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default SidebarContent;
