import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { deleteSite } from '../services';

import toastr from 'toastr';

function DeleteSiteModal(props: any) {
  const handleDelete = async () => {
    const res = await deleteSite(props.id);
    if (res) {
      await props.getSites();
      await props.deleteToggleModal();
      toastr.success('Site has been successfully deleted', 'Success');
    }
  };
  return (
    <div>
      <Modal isOpen={props.modal} toggle={props.deleteToggleModal}>
        <ModalHeader toggle={props.deleteToggleModal}>Delete site</ModalHeader>
        <ModalBody>
          <div>Are you sure you want to delete this site?</div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleDelete()}>
            Delete
          </Button>{' '}
          <Button color="secondary" onClick={props.deleteToggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default DeleteSiteModal;
