import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';

import { getMe } from '../services';
import { IAuth } from '../store/types';

const cookies = new Cookies();

// const token = cookies.get('hubAuthToken');

type IAuthContext = {
  isAuthenticated: boolean;
  setIsAuthenticated: (val: boolean) => void;
  user: IAuth;
  setUser: (val: IAuth) => void;
  error: string;
  setError: (val: string) => void;
  success: string;
  setSuccess: (val: string) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  clearState: () => void;
  logout: () => void;
  defaultRoute: string;
  setDefaultRoute: (val: string) => void;
  magicCode: string;
  setMagicCode: (val: string) => void;
};

export const AuthContext: React.Context<IAuthContext> = React.createContext({} as IAuthContext);

export const AuthProvider = ({ children }: { children: any }): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!cookies.get('hubAuthToken'));
  const [user, setUser] = useState<IAuth>({
    _id: '',
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    token: '',
    magicCode: '',
    projects: [],
    hub_access_type: '',
  });
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultRoute, setDefaultRoute] = useState('');
  const [magicCode, setMagicCode] = useState(cookies.get('hubMagicCode') || '');

  const clearState = () => {
    setError('');
    setSuccess('');
  };

  const logout = () => {
    /** Set redirect Url before log out */
    cookies.set('hubRedirectUrl', document.location.pathname, { path: '/' });
    cookies.remove('hubAuthUser', { path: '/' });
    cookies.remove('hubMagicCode', { path: '/' });
    cookies.remove('hubAuthToken', { path: '/' });
    setIsAuthenticated(false);
  };

  useEffect(() => {
    getMeData();
  }, []);

  const getMeData = async () => {
    const res = await getMe();
    const userData = res?.data;
    console.log('userData', userData);
    setUser(userData);
    cookies.set(
      'hubAuthUser',
      {
        _id: userData._id,
        first_name: userData.first_name,
        last_name: userData.last_name,
        username: userData.first_name + ' ' + userData.last_name,
        hub_access_type: userData.hub_access_type,
      },
      { path: '/' },
    );
    const projects = userData.projects?.map((project: any) => {
      const trial = project?.study;
      trial.url = `uat.${trial.study_code}.trial.lainaent.com`;
      return trial;
    });
    cookies.set('hubProjects', projects, { path: '/' });
  };

  // useEffect(() => {
  //   console.log('token', token);
  //   if (token) {
  //     dataService.authUserFromToken(token).then((resp) => {
  //       setIsAuthenticated(resp.isValidLogin);
  //       if (!resp.isValidLogin) {
  //         cookies.remove('hubAuthUser', { path: '/' });
  //         cookies.remove('hubAuthToken', { path: '/' });
  //         token = '';
  //         window.location.reload();
  //       }
  //     });
  //   }
  // }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        error,
        setError,
        success,
        setSuccess,
        loading,
        setLoading,
        clearState,
        logout,
        defaultRoute,
        setDefaultRoute,
        magicCode,
        setMagicCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContext => React.useContext(AuthContext);
