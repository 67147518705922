import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import styled from 'styled-components';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumb from '../components/Common/Breadcrumb';
import { useAuth } from '../context/AuthContext';
import { useData } from '../context/DataContext';
import { getMe } from '../services';
import lainaTile from '../assets/images/laina-tile-green.png';

const cookies = new Cookies();

const TrialItemLink = styled.a`
  display: inline-block;
  overflow-wrap: break-word;
  background-color: #fff;
  border-radius: 0.25rem;
  padding: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  width: 200px;
  text-align: center;
  // border-bottom: 3px solid #7db7e2;
  border-bottom: 3px solid ${(props: any) => props.borderColor};
  color: #004a6b;
  text-decoration: none;

  img {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
  }

  &:hover {
    background: #2f708e;
    color: #fff;

    img {
      filter: brightness(0) invert(1);
    }

    div {
      color: #fff;
    }
  }
`;

const TrialTitle = styled.div`
  color: ${(props: any) => props.textColor};
`;

const EmptyMessage = styled.p`
  font-size: 16px;
  background: white;
  padding: 10px;
`;

/**
 * Studies Page component.
 *
 * @remarks
 * Studies Page that parses and runs the command
 *
 * @component Studies Page
 * @category Page
 */
const StudiesPage = (): JSX.Element => {
  const { userProjects, setUserProjects } = useData();
  const { user, setUser, magicCode } = useAuth();
  const [loading, setLoading] = useState(false);

  const breadCrumbItems = [
    {
      link: '/dashboard',
      text: 'Your Studies',
    },
  ];

  useEffect(() => {
    getMeData();
  }, []);

  const getMeData = async () => {
    setLoading(true);
    const res = await getMe();
    const userData = res?.data;
    setUser(userData);
    cookies.set(
      'hubAuthUser',
      {
        _id: userData._id,
        first_name: userData.first_name,
        last_name: userData.last_name,
        username: userData.first_name + ' ' + userData.last_name,
        hub_access_type: userData.hub_access_type,
      },
      { path: '/' },
    );
    const projects = userData.projects?.map((project: any) => {
      const trial = project?.study;
      trial.url = `uat.${trial.study_code}.trial.lainaent.com`;
      return trial;
    });
    cookies.set('hubProjects', projects, { path: '/' });
    setUserProjects(projects);
    setLoading(false);
  };

  console.log(userProjects);
  return (
    <div className="page-content studies-page">
      <Container fluid className="container-page">
        <Breadcrumb items={breadCrumbItems} />
        <Row>
          {loading ? (
            <Col>Loading...</Col>
          ) : (
            <Col>
              {userProjects.length ? (
                userProjects.map((trial: any) => {
                  return (
                    // <Col md={3} key={trial.study_code}>
                    <TrialItemLink
                      target="_blank"
                      key={trial.study_code}
                      href={'https://' + trial.url + `/magic/${magicCode}`}
                      rel="noreferrer"
                      borderColor={trial.study_color}
                    >
                      <img src={lainaTile} alt="Tile" />
                      <TrialTitle textColor={trial.study_color}>{trial.study_name}</TrialTitle>
                    </TrialItemLink>
                    // </Col>
                  );
                })
              ) : (
                <EmptyMessage>
                  You don't have permission to access any of the Laina Studies. <br /> Contact{' '}
                  <a href="mailto:admin@lainaent.com">admin@lainaent.com</a> to request an access.
                </EmptyMessage>
              )}
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default StudiesPage;
