import { Field, Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Button, Col, Card, CardBody, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import styled from 'styled-components';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import { getRoles, getStudies, createUser } from '../../services';
import { Project } from '../../constants/types';
import toastr from 'toastr';
import Select from 'react-select';
import { useAuth } from '../../context/AuthContext';

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#727272',
    fontFamily: "'Lato', sans-serif !important",
    fontWeight: 300,
  }),
  multiValueLabel: (styles: any, { data }: any) => ({
    ...styles,
    color: data.color,
    cursor: 'pointer',
  }),
  multiValueRemove: (styles: any, { data }: any) => ({
    ...styles,
    color: data.color,
    cursor: 'pointer',
  }),
};

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  username: Yup.string().required('Username is required'),
  email: Yup.string().required('Email is required'),
});

const ErrorMsg = styled.p`
  color: #e23449;
  font-size: 14px;
`;

const StudyTag = styled.h5`
  color: #64a093;
`;

const RemoveLink = styled.a`
  color: #5d8aa1;
`;

const ProjectRow = styled.div`
  > div {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
  }
`;

const ProjectErrorMsg = styled.p`
  color: #e23449;
  font-size: 14px;
`;

function UserCreate() {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<Project[]>([]);
  const [roles, setRoles] = useState([]);
  const [studies, setStudies] = useState([]);
  const [error, setError] = useState('');
  const [projectErrors, setProjectErrors] = useState([]);
  const { user } = useAuth();

  const breadCrumbItems = [
    {
      link: '/users',
      text: 'User Management',
    },
    {
      link: '/users',
      text: 'Add User',
    },
  ];

  const initialValue = {
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    phone: '',
    company: '',
    hub_access_type: 'user',
    projects: [],
  };

  const getRolesData = async () => {
    const rolesRes = await getRoles();
    if (rolesRes && rolesRes.data) {
      setRoles(rolesRes.data.roles);
    }
  };

  const getStudiesData = async () => {
    const studiesRes = await getStudies();
    if (studiesRes && studiesRes.data) {
      const userStudies = user.projects?.map((project) => {
        return project.study.study_name;
      });

      setStudies(
        studiesRes.data.studies.filter((study: any) => {
          return userStudies?.includes(study.study_name);
        }),
      );
    }
  };

  const handleAddNewStudy = () => {
    setProjects((prev: any) => [...prev, { study: '', role: '' }]);
  };

  const handleRemoveClick = (index: number) => {
    setProjects((state: any) => state.filter((_: any, i: number) => i !== index));
  };

  useEffect(() => {
    getRolesData();
    getStudiesData();
  }, []);

  const handleSubmit = async (values: any, actions: any) => {
    try {
      setError('');
      setProjectErrors([]);
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        actions.setFieldError('email', 'Email format is invalid.');
        return;
      }

      let missingSites = false;
      let invalidProjects = false;
      const pErrors: any = [];
      projects.forEach((project: Project, index: number) => {
        const selectedStudy: any = studies.find((study: any) => study._id == project.study);

        if (project.study == '') {
          pErrors[index] = 'Please select study.';
          invalidProjects = true;
          return;
        }

        if (selectedStudy?.sites?.length > 0 && project.sites?.length == 0) {
          pErrors[index] = 'Please allow an access to one or more site locations.';
          missingSites = true;
        } else {
          pErrors[index] = '';
        }
      });
      setProjectErrors(pErrors);
      if (missingSites || invalidProjects) {
        // setError('Please allow an access to one or more site locations.');
        return;
      }
      await createUser({ ...values, projects });
      toastr.success('User has been successfully created.', 'Success');
      navigate('/users');
    } catch (e: any) {
      setError(e?.response?.data?.message);
    }
  };

  const handleChangeProjectStudy = (e: any, index: number) => {
    const studyId = e.target.value;
    setProjects((projects: Project[]) => {
      return projects.map((p: Project, i: number) => {
        if (i == index) {
          return {
            ...p,
            study: studyId,
            role: '',
            sites: [],
          };
        }
        return p;
      });
    });
  };

  const handleChangeProjectRole = (e: any, index: number) => {
    setProjects((projects: Project[]) => {
      return projects.map((p: Project, i: number) => {
        if (i == index) {
          return {
            ...p,
            role: e.target.value,
          };
        }
        return p;
      });
    });
  };

  const handleSelectProjectSites = (selectedSites: any, index: number) => {
    setProjects((projects: Project[]) => {
      return projects.map((p: Project, i: number) => {
        if (i == index) {
          return {
            ...p,
            sites: selectedSites,
          };
        }
        return p;
      });
    });
  };

  return (
    <div className="page-content">
      <Container fluid className="container-page">
        <Breadcrumb items={breadCrumbItems} />
        {/* <h3>Add User</h3> */}
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={initialValue}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched, values, handleChange }) => {
                    return (
                      <>
                        <Form>
                          <div className="login-form form-group">
                            <FormGroup>
                              <div>
                                <Label for="first_name">
                                  First Name <span>*</span>
                                </Label>
                                <Field
                                  name="first_name"
                                  id="first_name"
                                  type="text"
                                  value={values.first_name}
                                  placeholder="Enter Your first name"
                                  className={`form-control ${
                                    touched.first_name && errors.first_name ? 'is-invalid' : ''
                                  }`}
                                  onChange={(e: any) => {
                                    const value = e.target.value;
                                    const re = /^[a-zA-Z]*$/;
                                    if (value !== '' && !re.test(value)) {
                                      return;
                                    }
                                    handleChange(e);
                                  }}
                                  style={{ marginTop: '0px' }}
                                />
                              </div>
                              {errors.first_name && touched.first_name ? (
                                <ErrorMsg>{errors.first_name}</ErrorMsg>
                              ) : null}
                            </FormGroup>
                            <FormGroup>
                              <div>
                                <Label for="last_name">
                                  Last Name <span>*</span>
                                </Label>
                                <Field
                                  name="last_name"
                                  id="last_name"
                                  type="text"
                                  value={values.last_name}
                                  placeholder="Enter Your last name"
                                  className={`form-control ${
                                    touched.last_name && errors.last_name ? 'is-invalid' : ''
                                  }`}
                                  onChange={(e: any) => {
                                    const value = e.target.value;
                                    const re = /^[a-zA-Z]*$/;
                                    if (value !== '' && !re.test(value)) {
                                      return;
                                    }
                                    handleChange(e);
                                  }}
                                  style={{ marginTop: '0px' }}
                                />
                              </div>
                              {errors.last_name && touched.last_name ? (
                                <ErrorMsg>{errors.last_name}</ErrorMsg>
                              ) : null}
                            </FormGroup>
                            <FormGroup>
                              <div>
                                <Label for="username">
                                  Username <span>*</span>
                                </Label>
                                <Field
                                  name="username"
                                  id="username"
                                  type="text"
                                  value={values.username}
                                  placeholder="Enter Your username"
                                  className={`form-control ${
                                    touched.last_name && errors.username ? 'is-invalid' : ''
                                  }`}
                                  onChange={(e: any) => {
                                    const value = e.target.value;
                                    const re = /^[a-z0-9]*$/;
                                    if (value !== '' && !re.test(value)) {
                                      return;
                                    }
                                    handleChange(e);
                                  }}
                                  style={{ marginTop: '0px' }}
                                />
                              </div>
                              {errors.username && touched.username ? (
                                <ErrorMsg>{errors.username}</ErrorMsg>
                              ) : null}
                            </FormGroup>
                            <FormGroup>
                              <div>
                                <Label for="email">
                                  Email <span>*</span>
                                </Label>
                                <Field
                                  name="email"
                                  id="email"
                                  type="text"
                                  value={values.email}
                                  placeholder="Enter Your email"
                                  className={`form-control ${
                                    touched.email && errors.email ? 'is-invalid' : ''
                                  }`}
                                  style={{ marginTop: '0px' }}
                                />
                              </div>
                              {errors.email && touched.email ? (
                                <ErrorMsg>{errors.email}</ErrorMsg>
                              ) : null}
                            </FormGroup>
                            <FormGroup>
                              <div>
                                <Label for="phone">Phone</Label>
                                <Field
                                  name="phone"
                                  id="phone"
                                  type="text"
                                  value={values.phone}
                                  placeholder="Enter Your phone"
                                  className={`form-control ${
                                    touched.phone && errors.phone ? 'is-invalid' : ''
                                  }`}
                                  onChange={(e: any) => {
                                    const value = e.target.value;
                                    const re = /^[0-9\-]*$/;
                                    if (value !== '' && !re.test(value)) {
                                      return;
                                    }
                                    handleChange(e);
                                  }}
                                  style={{ marginTop: '0px' }}
                                />
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <div>
                                <Label for="company">Company</Label>
                                <Field
                                  name="company"
                                  id="company"
                                  type="text"
                                  value={values.company}
                                  placeholder="Enter Your company"
                                  className={`form-control ${
                                    touched.company && errors.company ? 'is-invalid' : ''
                                  }`}
                                  style={{ marginTop: '0px' }}
                                />
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <Label for="hub_access_type">
                                Role on Hub <span>*</span>
                              </Label>
                              <div>
                                {/* <FormGroup check inline>
                                    <Field type="radio" name="hub_access_type" value="user" />
                                    <Label check>User</Label>
                                  </FormGroup>
                                  <FormGroup check inline>
                                    <Field type="radio" name="hub_access_type" value="admin" />
                                    <Label check>Admin</Label>
                                  </FormGroup> */}
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="hub_access_type"
                                    id="hub_access_type_user"
                                    value="user"
                                    onChange={(e: any) => {
                                      handleChange(e);
                                    }}
                                    defaultChecked
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="hub_access_type_user"
                                  >
                                    User
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="hub_access_type"
                                    id="hub_access_type_admin"
                                    value="admin"
                                    onChange={(e: any) => {
                                      handleChange(e);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="hub_access_type_admin"
                                  >
                                    Admin
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="hub_access_type"
                                    id="hub_access_type_superadmin"
                                    value="superadmin"
                                    onChange={(e: any) => {
                                      handleChange(e);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="hub_access_type_superadmin"
                                  >
                                    Super Admin
                                  </label>
                                </div>
                              </div>
                            </FormGroup>
                            <div>
                              <StudyTag className="mb-2 mb-3">Study Assignments</StudyTag>
                            </div>
                            <div>
                              <Row>
                                <Col>
                                  <h6>Study Name </h6>
                                </Col>
                                <Col>
                                  <h6>Role Name </h6>
                                </Col>
                                <Col>
                                  <h6>Select Sites</h6>
                                </Col>
                                <Col></Col>
                              </Row>
                              {projects?.map((project: Project, index: number) => {
                                const selectedStudy: any = studies.find(
                                  (study: any) => study._id == project.study,
                                );
                                const userSelectedProject = user.projects?.find((project) => {
                                  return project.study._id == selectedStudy?._id;
                                });
                                const userSites = userSelectedProject?.sites ?? [];

                                const hasStudyError =
                                  projectErrors[index] == 'Please select study.';
                                const hasSiteError =
                                  projectErrors[index] ==
                                  'Please allow an access to one or more site locations.';

                                return (
                                  <ProjectRow key={index}>
                                    <Row>
                                      <Col className="study-assign pr-1 pl-0">
                                        <Input
                                          name={'study'}
                                          value={project.study}
                                          className={hasStudyError ? 'is-invalid' : ''}
                                          type="select"
                                          onChange={(e) => handleChangeProjectStudy(e, index)}
                                        >
                                          <option>Select</option>
                                          {studies.map((study: any) => (
                                            <option key={study._id} value={study._id}>
                                              {study.study_name}
                                            </option>
                                          ))}
                                        </Input>
                                      </Col>
                                      <Col className="study-assign pr-1">
                                        <Input
                                          name="role"
                                          value={project.role}
                                          type="select"
                                          onChange={(e) => handleChangeProjectRole(e, index)}
                                        >
                                          <option>Select</option>
                                          {roles.map((role: any) => (
                                            <option key={role._id} value={role._id}>
                                              {role.name}
                                            </option>
                                          ))}
                                        </Input>
                                      </Col>
                                      <Col className="study-assign pr-1">
                                        {userSites.length > 0 && (
                                          <Select
                                            isMulti
                                            name="sites"
                                            options={userSites}
                                            classNamePrefix={
                                              hasSiteError
                                                ? 'is-invalid react-select'
                                                : 'react-select'
                                            }
                                            styles={customStyles}
                                            onChange={(value) =>
                                              handleSelectProjectSites(value, index)
                                            }
                                            getOptionLabel={(option: any) => option.site_name}
                                            getOptionValue={(option: any) => option._id}
                                            closeMenuOnSelect={false}
                                            value={project.sites}
                                          />
                                        )}
                                      </Col>
                                      <Col className="study-assign">
                                        <div>
                                          <RemoveLink onClick={() => handleRemoveClick(index)}>
                                            Remove
                                          </RemoveLink>
                                        </div>
                                      </Col>
                                    </Row>
                                    {projectErrors[index] ? (
                                      <ProjectErrorMsg>{projectErrors[index]}</ProjectErrorMsg>
                                    ) : null}
                                  </ProjectRow>
                                );
                              })}

                              <div>
                                <RemoveLink onClick={() => handleAddNewStudy()}>
                                  + Assign new study
                                </RemoveLink>
                              </div>
                            </div>
                          </div>
                          {error ? <ErrorMsg>{error}</ErrorMsg> : null}
                          <Button color="secondary" onClick={() => navigate('/users')}>
                            Cancel
                          </Button>{' '}
                          <Button color="success" type="submit">
                            Send invitation
                          </Button>
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default UserCreate;
