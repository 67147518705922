import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Common/Breadcrumb';
import styled from 'styled-components';
import ActivateUserModal from '../../components/ActivateUserModal';
import DuplicateStudyWarningModal from '../../components/DuplicateStudyWarningModal';
import ChangePasswordModal from '../../components/ChangePasswordModal';
import {
  getRoles,
  getUser,
  getStudies,
  getUsersActivity,
  updateUser,
  deactivateUser,
  activateUser,
  changeUserPassword,
  sendResetPasswordEmail,
} from '../../services';
import { User, Project } from '../../constants/types';
import { useAuth } from '../../context/AuthContext';
import moment from 'moment';
import toastr from 'toastr';
import Select from 'react-select';

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#727272',
    fontFamily: "'Lato', sans-serif !important",
    fontWeight: 300,
  }),
  multiValueLabel: (styles: any, { data }: any) => ({
    ...styles,
    color: data.color,
    cursor: 'pointer',
  }),
  multiValueRemove: (styles: any, { data }: any) => ({
    ...styles,
    color: data.color,
    cursor: 'pointer',
  }),
};

/**
 * UserDetail Page component.
 *
 * @remarks
 * UserDetail Page
 *
 * @component UserDetail Page
 * @category Page
 */

const ErrorMsg = styled.p`
  color: #e23449;
  font-size: 14px;
`;

const EditLink = styled.a`
  color: #5d8aa1;
  text-decoration: underline !important;
`;

const ActiveUser = styled.a`
  color: #a77d7a;
  text-decoration: underline !important;
`;

const RemoveLink = styled.a`
  color: #5d8aa1;
`;

const ProjectRow = styled.div`
  > div {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
  }
`;

const ProjectErrorMsg = styled.p`
  color: #e23449;
  font-size: 14px;
`;

const UserDetail = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();
  const { user: authUser } = useAuth();
  const [projects, setProjects] = useState<Project[]>([]);
  const [roles, setRoles] = useState([]);
  const [studies, setStudies] = useState([]);
  const [error, setError] = useState('');
  const [isShowActivateUserModal, setIsShowActivateUserModal] = useState(false);
  const [isShowChangePwdModal, setIsShowChangePwdModal] = useState(false);
  const [isShowDuplicateModal, setIsShowDuplicateModal] = useState(false);
  const [user, setUser] = useState<User>();
  const [userActivity, setUsersActivity] = useState<any[]>([]);
  const [isEditFullName, setIsEditFullName] = useState(false);
  const [isEditRole, setIsEditRole] = useState(false);
  const [isEditMobile, setIsEditMobile] = useState(false);
  const [isEditCompany, setIsEditCompany] = useState(false);
  const [isEditUsername, setIsEditUsername] = useState(false);
  const [projectErrors, setProjectErrors] = useState([]);
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    // username: '',
    phone: '',
    company: '',
    hub_access_type: '',
  });

  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    // username: '',
    phone: '',
    company: '',
    hub_access_type: '',
  });

  const breadCrumbItems = [
    {
      link: '/users',
      text: 'User Management',
    },
    {
      link: '/users',
      text: 'Users List',
    },
    {
      link: '/users',
      text: 'View Profile',
    },
  ];

  const toggleActivateUserModal = () => {
    setIsShowActivateUserModal(!isShowActivateUserModal);
  };

  const toggleChangePwdModal = () => {
    setIsShowChangePwdModal(!isShowChangePwdModal);
  };

  const toggleDuplicateModal = () => {
    setIsShowDuplicateModal(!isShowDuplicateModal);
  };

  const toggleUserStatus = () => {
    if (!user) {
      return;
    }
    const userId = params.id as string;
    if (user?.isDeleted) {
      activateUser(userId);
      toastr.success('User has been activated successfully.', 'Success');
    } else {
      deactivateUser(userId);
      toastr.success('User has been deactivated successfully.', 'Success');
    }
    setUser({ ...user, isDeleted: !user.isDeleted });
  };

  const getRolesData = async () => {
    const rolesRes = await getRoles();
    if (rolesRes && rolesRes.data) {
      setRoles(rolesRes.data.roles);
    }
  };

  const getStudiesData = async () => {
    const studiesRes = await getStudies();
    if (studiesRes && studiesRes.data) {
      const userStudies = authUser.projects?.map((project) => {
        return project.study.study_name;
      });

      setStudies(
        studiesRes.data.studies.filter((study: any) => {
          return userStudies?.includes(study.study_name);
        }),
      );
    }
  };

  const getUserData = async () => {
    if (!params.id) {
      return;
    }
    const res = await getUser(params.id);
    const userData = res?.data?.user;
    setUser(userData);
    setValues({
      first_name: userData.first_name,
      last_name: userData.last_name,
      // username: userData.username,
      phone: userData.phone,
      company: userData.company,
      hub_access_type: userData.hub_access_type,
    });
    const activityRes = await getUsersActivity(res?.data?.user._id as string);
    setUsersActivity(activityRes?.data?.logs);
    setProjects(
      res?.data.user.projects.map((project: any) => ({
        role: project.role?._id,
        study: project.study?._id,
        sites: project.sites,
      })),
    );
  };

  const getUserActivity = async () => {
    if (!params.id) {
      return;
    }
    const activityRes = await getUsersActivity(params.id);
    setUsersActivity(activityRes?.data?.logs);
  };

  const handleAddNewStudy = () => {
    setProjects((prev: any) => [...prev, { study: '', role: '' }]);
  };

  useEffect(() => {
    getRolesData();
    getStudiesData();
    getUserData();
  }, []);

  const handleRemoveClick = (index: number) => {
    setProjects((state: any) => state.filter((_: any, i: number) => i !== index));
  };

  console.log(authUser);

  const handleSubmit = async () => {
    if (!params.id) {
      return;
    }
    setProjectErrors([]);
    console.log('before submit', values, projects);
    const uniqueStudies = [...new Set(projects.map((project) => project.study))];
    if (uniqueStudies.length != projects.length) {
      toggleDuplicateModal();
      return;
    }

    let missingSites = false;
    let invalidProjects = false;
    const pErrors: any = [];
    projects.forEach((project: Project, index: number) => {
      const selectedStudy: any = studies.find((study: any) => study._id == project.study);

      if (project.study == '') {
        pErrors[index] = 'Please select study.';
        invalidProjects = true;
        return;
      }

      if (selectedStudy?.sites?.length > 0 && project.sites?.length == 0) {
        pErrors[index] = 'Please allow an access to one or more site locations.';
        missingSites = true;
      } else {
        pErrors[index] = '';
      }
    });
    setProjectErrors(pErrors);
    if (missingSites || invalidProjects) {
      // setError('Please allow an access to one or more site locations.');
      return;
    }
    try {
      setError('');
      const res: any = await updateUser(params.id, {
        ...values,
        lastLogin: {
          time: user?.lastLogin?.time,
        },
        projects,
      });
      if (res && res.data?.result) {
        setUser(res.data.result);
      }
      toastr.success('User has been successfully updated.', 'Success');
    } catch (e: any) {
      setError(e?.response?.data?.message);
    }
  };

  console.log(projectErrors);
  const handleChangeProjectStudy = (e: any, index: number) => {
    setProjects((projects: Project[]) => {
      return projects.map((p: Project, i: number) => {
        if (i == index) {
          return {
            ...p,
            study: e.target.value,
            role: '',
            sites: [],
          };
        }
        return p;
      });
    });
  };

  const handleChangeProjectRole = (e: any, index: number) => {
    setProjects((projects: Project[]) => {
      return projects.map((p: Project, i: number) => {
        if (i == index) {
          return {
            ...p,
            role: e.target.value,
          };
        }
        return p;
      });
    });
  };

  const handleSelectProjectSites = (selectedSites: any, index: number) => {
    setProjects((projects: Project[]) => {
      return projects.map((p: Project, i: number) => {
        if (i == index) {
          return {
            ...p,
            sites: selectedSites,
          };
        }
        return p;
      });
    });
  };

  const handleUpdateProfile = () => {
    handleSubmit();
    setIsEditFullName(false);
    setIsEditMobile(false);
    setIsEditCompany(false);
    setIsEditRole(false);
    setIsEditUsername(false);
  };

  const changePassword = async (password: string) => {
    if (!params.id) {
      return;
    }
    const res = await changeUserPassword(params.id, password);
    toastr.success('Password has been successfully changed', 'Success');
    getUserActivity();
    return res;
  };

  const sendResetPasswordLink = async () => {
    if (!params.id) {
      return;
    }
    const res = await sendResetPasswordEmail(params.id);
    toastr.success('Reset Password Email is sent', 'Success');
  };

  return (
    <div className="page-content">
      <Container fluid className="container-page">
        <Breadcrumb items={breadCrumbItems} />
        <div className="manage-user">
          <Card className="card-main">
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <h5 style={{ marginBottom: '26px' }}>Profile Information</h5>
                    <div className="profile-details">
                      <Row className="mb-2">
                        <Col md={3}>
                          <b>Full Name : </b>
                        </Col>
                        <Col>
                          {!isEditFullName ? (
                            <p>
                              <span>
                                {values?.first_name} {values?.last_name}
                              </span>
                              <span className="badge badge-pill badge-secondary font-size-12 ml-2">
                                HUB{' '}
                                {user?.hub_access_type?.toLowerCase() == 'superadmin'
                                  ? 'Super Admin'
                                  : user?.hub_access_type?.toLowerCase() == 'admin'
                                  ? 'Admin'
                                  : 'User'}
                              </span>
                            </p>
                          ) : (
                            <>
                              <FormGroup>
                                <input
                                  className={
                                    errors.first_name ? 'is-invalid form-control' : 'form-control'
                                  }
                                  name={'first_name'}
                                  value={values.first_name}
                                  onChange={(e: any) => {
                                    const value = e.target.value;
                                    const re = /^[a-zA-Z]*$/;
                                    if (value !== '' && !re.test(value)) {
                                      return;
                                    }
                                    setValues({ ...values, first_name: e.target.value });
                                    if (e.target.value !== '')
                                      setErrors({ ...errors, first_name: '' });
                                  }}
                                />
                                {errors.first_name && (
                                  <span className="validation-error">{errors.first_name}</span>
                                )}
                              </FormGroup>
                              <FormGroup>
                                <input
                                  className={
                                    errors.last_name ? 'is-invalid form-control' : 'form-control'
                                  }
                                  name={'last_name'}
                                  value={values.last_name}
                                  onChange={(e: any) => {
                                    const value = e.target.value;
                                    const re = /^[a-zA-Z]*$/;
                                    if (value !== '' && !re.test(value)) {
                                      return;
                                    }
                                    setValues({ ...values, last_name: e.target.value });
                                    if (e.target.value !== '')
                                      setErrors({ ...errors, last_name: '' });
                                  }}
                                />
                                {errors.last_name && (
                                  <span className="validation-error">{errors.last_name}</span>
                                )}
                              </FormGroup>
                            </>
                          )}
                        </Col>
                        <Col className="text-right">
                          {!isEditFullName ? (
                            <EditLink onClick={() => setIsEditFullName(!isEditFullName)}>
                              Edit
                            </EditLink>
                          ) : (
                            <EditLink onClick={handleUpdateProfile}>Update</EditLink>
                          )}
                        </Col>
                      </Row>
                      {/* <Row className="mb-2">
                        <Col md={3}>
                          <b>Username : </b>
                        </Col>
                        <Col>
                          {!isEditUsername ? (
                            <p>{values?.username ?? '-'}</p>
                          ) : (
                            <FormGroup>
                              <input
                                className={
                                  errors.username ? 'is-invalid form-control' : 'form-control'
                                }
                                name="username"
                                value={values.username}
                                onChange={(e: any) => {
                                  const value = e.target.value;
                                  const re = /^[a-zA-Z0-9]*$/;
                                  if (value !== '' && !re.test(value)) {
                                    return;
                                  }
                                  setValues({ ...values, username: e.target.value });
                                }}
                              />
                              {errors.username && (
                                <span className="validation-error">{errors.username}</span>
                              )}
                            </FormGroup>
                          )}
                        </Col>
                        <Col className="text-right">
                          {!isEditUsername ? (
                            <EditLink onClick={() => setIsEditUsername(!isEditUsername)}>
                              Edit
                            </EditLink>
                          ) : (
                            <EditLink onClick={handleUpdateProfile}>Update</EditLink>
                          )}
                        </Col>
                      </Row> */}
                      <Row className="mb-2">
                        <Col md={3}>
                          <b>Username : </b>
                        </Col>
                        <Col>
                          <p>{user?.username ?? '-'}</p>
                        </Col>
                        <Col className="text-right"></Col>
                      </Row>
                      <Row className="mb-2">
                        <Col md={3}>
                          <b>Mobile : </b>
                        </Col>
                        <Col>
                          {!isEditMobile ? (
                            <p>{values?.phone}</p>
                          ) : (
                            <FormGroup>
                              <input
                                className={
                                  errors.phone ? 'is-invalid form-control' : 'form-control'
                                }
                                name="phone"
                                value={values.phone}
                                onChange={(e: any) => {
                                  const value = e.target.value;
                                  const re = /^[0-9\-]*$/;
                                  if (value !== '' && !re.test(value)) {
                                    return;
                                  }
                                  setValues({ ...values, phone: e.target.value });
                                }}
                              />
                              {errors.phone && (
                                <span className="validation-error">{errors.phone}</span>
                              )}
                            </FormGroup>
                          )}
                        </Col>
                        <Col className="text-right">
                          {!isEditMobile ? (
                            <EditLink onClick={() => setIsEditMobile(!isEditMobile)}>Edit</EditLink>
                          ) : (
                            <EditLink onClick={handleUpdateProfile}>Update</EditLink>
                          )}
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col md={3}>
                          <b>E-mail : </b>
                        </Col>
                        <Col>
                          <p>{user?.email}</p>
                        </Col>
                        <Col className="text-right"></Col>
                      </Row>
                      <Row className="mb-2">
                        <Col md={3}>
                          <b>Password : </b>
                        </Col>
                        <Col>
                          <p>******</p>
                        </Col>
                        {authUser?.hub_access_type == 'superadmin' && (
                          <Col className="text-right">
                            <EditLink
                              onClick={() => {
                                toggleChangePwdModal();
                              }}
                            >
                              Change Password
                            </EditLink>
                            &nbsp; | &nbsp;
                            <EditLink
                              onClick={() => {
                                sendResetPasswordLink();
                              }}
                            >
                              Send Reset Password Link
                            </EditLink>
                          </Col>
                        )}
                      </Row>
                      <Row className="mb-2">
                        <Col md={3}>
                          <b>Company : </b>
                        </Col>
                        <Col>
                          {!isEditCompany ? (
                            <p>{values?.company}</p>
                          ) : (
                            <FormGroup>
                              <input
                                className={
                                  errors.company ? 'is-invalid form-control' : 'form-control'
                                }
                                name="company"
                                value={values.company}
                                onChange={(e: any) => {
                                  setValues({ ...values, company: e.target.value });
                                }}
                              />
                              {errors.company && (
                                <span className="validation-error">{errors.company}</span>
                              )}
                            </FormGroup>
                          )}
                        </Col>
                        <Col className="text-right">
                          {!isEditCompany ? (
                            <EditLink onClick={() => setIsEditCompany(!isEditCompany)}>
                              Edit
                            </EditLink>
                          ) : (
                            <EditLink onClick={handleUpdateProfile}>Update</EditLink>
                          )}
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col md={3}>
                          <b>Role on Hub : </b>
                        </Col>
                        <Col>
                          {!isEditRole ? (
                            <p>
                              {values?.hub_access_type?.toLowerCase() == 'superadmin'
                                ? 'Super Admin'
                                : values?.hub_access_type?.toLowerCase() == 'admin'
                                ? 'Admin'
                                : 'User'}
                            </p>
                          ) : (
                            <FormGroup>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="hub_access_type"
                                  id="hub_access_type_user"
                                  value="user"
                                  onChange={(e: any) => {
                                    setValues({ ...values, hub_access_type: e.target.value });
                                  }}
                                  defaultChecked={values.hub_access_type == 'user'}
                                />
                                <label className="form-check-label" htmlFor="hub_access_type_user">
                                  User
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="hub_access_type"
                                  id="hub_access_type_admin"
                                  value="admin"
                                  onChange={(e: any) => {
                                    setValues({ ...values, hub_access_type: e.target.value });
                                  }}
                                  defaultChecked={values.hub_access_type == 'admin'}
                                />
                                <label className="form-check-label" htmlFor="hub_access_type_admin">
                                  Admin
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="hub_access_type"
                                  id="hub_access_type_superadmin"
                                  value="superadmin"
                                  onChange={(e: any) => {
                                    setValues({ ...values, hub_access_type: e.target.value });
                                  }}
                                  defaultChecked={values.hub_access_type == 'superadmin'}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="hub_access_type_superadmin"
                                >
                                  Super Admin
                                </label>
                              </div>
                              {errors.hub_access_type && (
                                <span className="validation-error">{errors.hub_access_type}</span>
                              )}
                            </FormGroup>
                          )}
                        </Col>
                        <Col className="text-right">
                          {!isEditRole ? (
                            <EditLink onClick={() => setIsEditRole(!isEditRole)}>Edit</EditLink>
                          ) : (
                            <EditLink onClick={handleUpdateProfile}>Update</EditLink>
                          )}
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col md={3}>
                          <b>Account Status : </b>
                        </Col>
                        <Col>
                          <p className={user?.isDeleted ? 'text-danger' : ''}>
                            {user?.isDeleted ? 'Inactive' : 'Active'}
                          </p>
                        </Col>
                        <Col className="text-right">
                          <ActiveUser onClick={() => toggleActivateUserModal()}>
                            {user?.isDeleted ? 'Activate' : 'Deactivate'} User
                          </ActiveUser>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col md={3}>
                          <b>Last Login : </b>
                        </Col>
                        <Col>
                          <p>
                            {user?.lastLogin?.time
                              ? `${moment(user?.lastLogin?.time).format('YYYY-MM-DD hh:mm:ss')} (${
                                  user?.lastLogin?.timezone
                                })`
                              : 'Never logged in'}
                          </p>
                        </Col>
                        <Col></Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <h5>Activity Log</h5>
                    <div style={{ maxHeight: 500, overflow: 'auto' }}>
                      {userActivity.map((log) => {
                        return (
                          <div className="mb-2" key={log._id}>
                            <div>
                              {log.description} on{' '}
                              {moment(log.updatedAt).format('YYYY-MM-DD hh:mm:ss')}
                            </div>
                            <div>(IP: {log.ip})</div>
                          </div>
                        );
                      })}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <h5 style={{ marginBottom: '26px' }} className="mr-2">
                        Study Assignments
                      </h5>
                      <EditLink onClick={() => handleAddNewStudy()}>+ Add new study</EditLink>
                    </div>
                    <div className="profile-info">
                      <Row>
                        <Col>
                          <h6>Study Name </h6>
                        </Col>
                        <Col>
                          <h6>Role Name </h6>
                        </Col>
                        <Col>
                          <h6>Select Sites</h6>
                        </Col>
                        <Col></Col>
                      </Row>
                      {projects?.map((project: Project, index: number) => {
                        const selectedStudy: any = studies.find(
                          (study: any) => study._id == project.study,
                        );
                        const userSelectedProject = authUser.projects?.find((project) => {
                          return project.study._id == selectedStudy?._id;
                        });
                        const userSites = userSelectedProject?.sites ?? [];
                        return (
                          <ProjectRow key={index}>
                            <Row>
                              <Col className="study-assign pr-1 pl-0">
                                <Input
                                  name="study"
                                  value={project.study}
                                  type="select"
                                  onChange={(e) => handleChangeProjectStudy(e, index)}
                                >
                                  <option>Select</option>
                                  {studies.map((study: any) => (
                                    <option key={study._id} value={study._id}>
                                      {study.study_name}
                                    </option>
                                  ))}
                                </Input>
                              </Col>
                              <Col className="study-assign pr-1">
                                <Input
                                  name="role"
                                  value={project.role}
                                  type="select"
                                  onChange={(e) => handleChangeProjectRole(e, index)}
                                >
                                  <option>Select</option>
                                  {roles.map((role: any) => (
                                    <option key={role._id} value={role._id}>
                                      {role.name}
                                    </option>
                                  ))}
                                </Input>
                              </Col>
                              <Col className="study-assign pr-1">
                                {userSites.length > 0 && (
                                  <Select
                                    isMulti
                                    name="sites"
                                    options={userSites}
                                    classNamePrefix="react-select"
                                    styles={customStyles}
                                    onChange={(value) => handleSelectProjectSites(value, index)}
                                    getOptionLabel={(option: any) => option.site_name}
                                    getOptionValue={(option: any) => option._id}
                                    closeMenuOnSelect={false}
                                    value={project.sites}
                                  />
                                )}
                              </Col>
                              <Col className="study-assign">
                                <div>
                                  <RemoveLink onClick={() => handleRemoveClick(index)}>
                                    Remove
                                  </RemoveLink>
                                </div>
                              </Col>
                            </Row>
                            {projectErrors[index] ? (
                              <ProjectErrorMsg>{projectErrors[index]}</ProjectErrorMsg>
                            ) : null}
                          </ProjectRow>
                        );
                      })}
                    </div>

                    {error ? <ErrorMsg>{error}</ErrorMsg> : null}
                    <Row>
                      <Col>
                        <Button color="primary" onClick={handleSubmit}>
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Card>
        </div>
      </Container>
      <ActivateUserModal
        isOpen={isShowActivateUserModal}
        toggleModal={toggleActivateUserModal}
        toggleUserStatus={toggleUserStatus}
        isDeleted={user?.isDeleted}
        userId={params.id}
      />
      <DuplicateStudyWarningModal
        isOpen={isShowDuplicateModal}
        toggleModal={toggleDuplicateModal}
      />
      <ChangePasswordModal
        isOpen={isShowChangePwdModal}
        toggleModal={toggleChangePwdModal}
        changePassword={changePassword}
      />
    </div>
  );
};

export default UserDetail;
